.home {
  padding: 20px;
}

/* Hero Section */
.hero {
  text-align: center;
  background-color: #f3f4f6;
  padding: 40px;
}

.hero h1 {
  font-size: 2.5em;
  margin-bottom: 10px;
}

.hero p {
  font-size: 1.2em;
}

.user-section {
  background-color: #f9fafb;
  border-radius: 5px;
  border-color: #e5e7eb;
  width: 100%;
  padding: 20px;
  margin: 20px 0;
 
}

/* About Section */
.about-section, .services-section, .expertise-section, .testimonials-section, .contact-section {
  margin: 20px 10px;
  padding: 20px;
  margin-left: 50px;
  margin-right: 50px;
  background-color: #ffffff;
  border-radius: 5px;
}

.about-section h2, .services-section h2, .expertise-section h2, .testimonials-section h2, .contact-section h2 {
  font-size: 1.8em;
  margin-bottom: 10px;
}

/* Services Section */
.service-cards {
  display: flex;
  justify-content: space-around;
  gap: 10px;
}

.service-card {
  background-color: #f9fafb;
  padding: 15px;
  border: 1px solid #e5e7eb;
  border-radius: 5px;
  width: 30%;
  text-align: center;
}

.service-card h3 {
  font-size: 1.3em;
}

/* Testimonials Section */
.testimonials-section {
  background-color: #f3f4f6;
}

.testimonial {
  font-style: italic;
  margin-top: 10px;
}

/* Contact Section */
.contact-button {
  background-color: #4f46e5;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-button:hover {
  background-color: #3b41c5;
}

@media (max-width: 768px) {
  .service-cards {
    grid-template-columns: repeat(2, 1fr);  /* 2 columns on smaller screens */
  }
  .about-section {
    width: 100%;
    
  }
}

@media (max-width: 480px) {
  .service-cards {
    grid-template-columns: 1fr;  /* 1 column on very small screens */
  }
  .about-section {
    width: 100%;
    
  }
}


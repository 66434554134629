/* styles.css */

@import url( 
"https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); 

/* * { 
margin: 0; 
padding: 0; 
box-sizing: border-box; 
font-family: "Poppins", sans-serif; 
}  */
:root { 
--background-color1: #fafaff; 
--background-color2: #ffffff; 
--background-color3: #ededed; 
--background-color4: #cad7fda4; 
--primary-color: #1976d2;  
--secondary-color: #3985cb; 
--Border-color: #498d22; 
--one-use-color: #4e7fb4; 
--two-use-color: #498b54; 
} 
/* body { 
background-color: var(--background-color4); 
max-width: 100%; 
overflow-x: hidden; 
}  */

header2 { 
height: 70px; 
width: 100vw; 
padding: 0 30px; 
background-color: var(--background-color1); 
position: fixed; 
z-index: 100; 
box-shadow: 1px 1px 15px rgba(161, 182, 253, 0.825); 
display: flex; 
justify-content: space-between; 
align-items: center; 
}  

.logo { 
font-size: 27px; 
font-weight: 600; 
color: rgb(47, 141, 70); 
} 

.icn { 
height: 30px; 
} 
.menuicn { 
cursor: pointer; 
height: 80px;
width: 90px;
} 

.searchbar, 
.message, 
.logosec { 
display: flex; 
align-items: center; 
justify-content: center; 

} 

.searchbar2 { 
display: none; 
} 


.logosec { 
gap: 60px; 
} 

.searchbar input { 
width: 250px; 
height: 42px; 
border-radius: 50px 0 0 50px; 
background-color: var(--background-color3); 
padding: 0 20px; 
font-size: 15px; 
outline: none; 
border: none; 
} 
.searchbtn { 
width: 50px; 
height: 42px; 
display: flex; 
align-items: center; 
justify-content: center; 
border-radius: 0px 50px 50px 0px; 
background-color: var(--secondary-color); 
cursor: pointer; 
} 

.hamburger {
  
  font-size: 1.8rem;
  background: none;
  border: none;
  cursor: pointer;
}

.message { 
gap: 40px; 
position: relative; 
cursor: pointer; 
} 
.circle { 
height: 7px; 
width: 7px; 
position: absolute; 
background-color: #fa7bb4; 
border-radius: 50%; 
left: 19px; 
top: 8px; 
} 
.dp {
    position: relative;
    cursor: pointer;
  }
  .dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 150px;
    z-index: 10;

      
      
    
    
  }
  
  .dropdown ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .dropdown li {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #eee;
  }
  
  .dropdown li:hover {
    background-color: #f5f5f5;
  }
  
  .dropdown li:last-child {
    border-bottom: none;
  }
    
.main-container { 
display: flex; 
width: 100vw; 
position: relative; 
top: 70px; 
z-index: 1; 
} 
.dpicn { 
height: 42px; 
} 

.main { 
height: calc(100vh - 70px); 
width: 100%; 
overflow-y: scroll; 
overflow-x: hidden; 
padding: 40px 30px 30px 30px; 
} 

.main::-webkit-scrollbar-thumb { 
background-image: 
        linear-gradient(to bottom, rgb(0, 0, 85), rgb(0, 0, 50)); 
} 
.main::-webkit-scrollbar { 
width: 5px; 
} 
.main::-webkit-scrollbar-track { 
background-color: #9e9e9eb2; 
} 

.box-container { 
display: flex; 
justify-content: space-evenly; 
align-items: center; 
flex-wrap: wrap; 
gap: 20px; 
} 
.nav { 
min-height: 91vh; 
width: 250px; 
background-color: var(--background-color2); 
position: absolute; 
top: 0px; 
left: 00; 
box-shadow: 1px 1px 10px rgba(198, 189, 248, 0.825); 
display: flex; 
flex-direction: column; 
justify-content: space-between; 
overflow: hidden; 
padding: 30px 0 20px 10px; 
} 
.navcontainer { 
height: calc(100vh - 70px); 
width: 250px; 
position: relative; 
overflow-y: scroll; 
overflow-x: hidden; 
transition: all 0.5s ease-in-out; 
} 
.navcontainer::-webkit-scrollbar { 
display: none; 
} 
.navclose { 
width: 80px; 
} 
.nav-option { 
width: 250px; 
height: 50px; 
display: flex; 
align-items: center; 

padding: 0 30px 0 20px; 
gap: 10px; 
transition: all 0.1s ease-in-out; 
} 
.nav-option:hover { 
border-left: 5px solid #a2a2a2; 
background-color: #dadada; 

cursor: pointer; 
} 
.nav-img { 
height: 30px; 
} 

.nav-upper-options { 
display: flex; 
flex-direction: column; 
align-items: center; 
gap: 30px; 
} 

.option1 { 
/* border-left: 5px solid #010058af;  */
/* background-color: var(--Border-color);  */
color: white; 
cursor: pointer; 
} 
.option1:hover { 
border-left: 5px solid #010058af; 
background-color: var(--Border-color); 
} 
.box { 
height: 130px; 
width: 250px; 

border-radius: 20px; 
box-shadow: 3px 3px 10px rgba(0, 30, 87, 0.751); 
padding-left: 10px; 
padding-right: 10px; 
display: flex; 
align-items: center; 
justify-content: space-around; 
cursor: pointer; 
transition: transform 0.3s ease-in-out; 
} 
@media (max-width: 768px) {
    .box {
      width: 100%;
    }
    .hamburger {
      display: block;
    }
  }
.box:hover { 
transform: scale(1.08); 
} 

.box:nth-child(1) { 
background-color: var(--one-use-color); 
} 
.box:nth-child(2) { 
background-color: var(--two-use-color); 
} 
.box:nth-child(3) { 
background-color: var(--one-use-color); 
} 
.box:nth-child(4) { 
background-color: var(--two-use-color); 
} 

.box img { 
height: 50px; 
} 
.box .text { 
color: white; 
} 
.topic { 
font-size: 13px; 
font-weight: 400; 
letter-spacing: 1px; 
} 

.topic-heading { 
font-size: 20px; 
letter-spacing: 3px; 
} 

.report-container { 
min-height: 300px; 
max-width: 1200px; 
margin: 20px auto 0px auto; 
background-color: #ffffff; 
border-radius: 30px; 
box-shadow: 3px 3px 10px rgb(188, 188, 188); 
padding: 0px 20px 20px 20px; 
} 
.report-header { 
height: 80px; 
width: 100%; 
display: flex; 
align-items: center; 
justify-content: space-between; 
padding: 20px 20px 10px 20px; 
border-bottom: 2px solid rgba(0, 20, 151, 0.59); 
} 


.recent-Articles { 
font-size: 30px; 
font-weight: 600; 
color: #1976d2; 
} 

.header-row {
  display: flex;
  justify-content: space-between; /* Push items to opposite ends */
  align-items: center; /* Vertically align items */
  margin-bottom: 20px; /* Add spacing below the row */
}

.header-row h1 {
  margin: 0; /* Remove default margin */
  font-size: 1.5rem; /* Adjust size as needed */
}

.header-row .view {
  background-color: #007bff; /* Button color */
  color: #fff; /* Text color */
  padding: 10px 20px; /* Adjust padding */
  border: none;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  font-size: 1rem;
}

.header-row .view:hover {
  background-color: #0056b3; /* Darker color on hover */
}



.view { 
height: 35px; 
width: 90px; 
border-radius: 8px; 

background-color: #1976d2;
color: white; 
font-size: 15px; 
border: none; 
cursor: pointer; 
} 

.report-body { 
max-width: 1110px; 
overflow-x: auto; 
padding: 10px; 
} 
.report-topic-heading, 
.item1 { 
width: 1100px; 
display: flex; 
justify-content: space-between; 
align-items: center; 
} 
.t-op { 
font-size: 18px; 
letter-spacing: 0px; 
} 

.items { 
width: 1120px; 
margin-top: 15px; 
} 

.item1 { 
margin-top: 20px; 
} 
.t-op-nextlvl { 
font-size: 14px; 
letter-spacing: 0px; 
font-weight: 600; 
} 

.label-tag { 
width: 100px; 
text-align: center; 
background-color: #00b100; 
color: white; 
border-radius: 4px; 
}

.label-tag-red { 
    width: 100px; 
    text-align: center; 
    background-color: #cb4113; 
    color: white; 
    border-radius: 4px; 
    }

/* for dialog */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .dialog {
    background: white;
    border-radius: 8px;
    padding: 20px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .form-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .form-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .form-buttons button[type="button"] {
    background-color: #f44336;
    color: white;
  }
  
  .form-buttons button[type="submit"] {
    background-color: #4caf50;
    color: white;
  }
  
/* Responsive CSS Here */
@media screen and (max-width: 950px) { 
    .nav-img { 
        height: 25px; 
    } 
    .nav-option { 
        gap: 30px; 
    } 
    .nav-option h3 { 
        font-size: 15px; 
    } 
    .report-topic-heading, 
    .item1, 
    .items { 
        width: 800px; 
    } 
    } 
    
@media screen and (max-width: 850px) { 
  .box { 
    height:80px; 
    
    border-radius: 10px; 
    box-shadow: 3px 3px 10px rgba(0, 30, 87, 0.751); 
    padding-left: 10px; 
    padding-right: 10px; 
    display: flex; 
    align-items: center; 
    justify-content: space-around; 
    cursor: pointer; 
    transition: transform 0.3s ease-in-out; 
    } 
    .nav-img { 
        height: 30px; 
    } 
    .nav-option { 
        gap: 30px; 
    } 
    .nav-option h3 { 
        font-size: 20px; 
    } 
    .report-topic-heading, 
    .item1, 
    .items { 
        width: 700px; 
    } 
    .navcontainer { 
        width: 100vw; 
        position: absolute; 
        transition: all 0.6s ease-in-out; 
        top: 0; 
        left: -100vw; 
    } 
    .nav { 
        width: 100%; 
        position: absolute; 
    } 
    .navclose { 
        left: 00px; 
    } 
    .searchbar { 
        display: none; 
    } 
    .main { 
        padding: 40px 30px 30px 30px; 
    } 
    .searchbar2 { 
        width: 100%; 
        display: flex; 
        margin: 0 0 40px 0; 
        justify-content: center; 
    } 
    .searchbar2 input { 
        width: 350px; 
        height: 42px; 
        border-radius: 50px 0 0 50px; 
        background-color: var(--background-color3); 
        padding: 0 20px; 
        font-size: 15px; 
        border: 2px solid var(--secondary-color); 
    } 
    } 
    
@media screen and (max-width: 490px) { 
    .message { 
        display: none; 
    } 
    .logosec { 
        width: 100%; 
        justify-content: space-between; 
    } 
    .logo { 
        font-size: 20px; 
    } 
    .menuicn { 
        height: 25px; 
    } 
    .nav-img { 
        height: 25px; 
    } 
    .nav-option { 
        gap: 25px; 
    } 
    .nav-option h3 { 
        font-size: 12px; 
    } 
    .nav-upper-options { 
        gap: 15px; 
    } 
    .recent-Articles { 
        font-size: 20px; 
    } 
    .report-topic-heading, 
    .item1, 
    .items { 
        width: 550px; 
    } 
    } 
    
@media screen and (max-width: 400px) { 
    .recent-Articles { 
        font-size: 17px; 
    } 
    .view { 
        width: 60px; 
        font-size: 10px; 
        height: 27px; 
    } 
    .report-header { 
        height: 60px; 
        padding: 10px 10px 5px 10px; 
    } 
    .searchbtn img { 
        height: 20px; 
    } 
    } 
    
@media screen and (max-width: 320px) { 
    .recent-Articles { 
        font-size: 12px; 
    } 
    .view { 
        width: 50px; 
        font-size: 8px; 
        height: 27px; 
    } 
    .report-header { 
        height: 60px; 
        padding: 10px 5px 5px 5px; 
    } 
    .t-op { 
        font-size: 12px; 
    } 
    .t-op-nextlvl { 
        font-size: 10px; 
    } 
    .report-topic-heading, 
    .item1, 
    .items { 
        width: 300px; 
    } 
    .report-body { 
        padding: 10px; 
    } 
    .label-tag { 
        width: 70px; 
    } 
    .searchbtn { 
        width: 40px; 
    } 
    .searchbar2 input { 
        width: 180px; 
    } 
    }